/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
function SEO({
  description,
  lang,
  meta,
  title,
  originUrl,
  imageUrl,
  siteUrl,
  schema,
  children,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            siteImage
          }
        }
      }
    `
  );

  useEffect(() => {
    import("firebase/app").then((firebase) => {
      const app = firebase.initializeApp(config);

      import("firebase/analytics").then((analytics) => {
        analytics.getAnalytics(app);
      });
    });
  }, []);

  const defaultTitle = site.siteMetadata?.title;
  const author = site.siteMetadata?.author;
  const defaultUrl = siteUrl || site.siteMetadata?.siteUrl;
  const metaImg = imageUrl || "https://n5now.com/preview.png";
  const siteTitle = title || "N5 Now";
  const htmlLanguage = lang === "pr" ? "pt" : lang;
  const structData =
    schema ||
    `{
    "@context": "https://schema.org",
    "@type": "Article",
    "url": "${defaultUrl}",
  }`;
  const viewport =
    "width=device-width, initial-scale=1, maximum-scale=1 shrink-to-fit=no";

  const postHogScript = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3631198,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

  return (
    <Helmet
      htmlAttributes={{ htmlLanguage }}
      title={title}
      description={description}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={[
        {
          rel: `canonical`,
          href: defaultUrl,
          hrefLang: lang === "pr" ? "pt" : lang,
        },
        {
          rel: `alternate`,
          href:
            lang === "en"
              ? defaultUrl.replace(`.com/`, ".com/pr/")
              : defaultUrl.replace(`.com/${lang}`, ".com/pr"),
          hrefLang: "pt",
        },
        {
          rel: `alternate`,
          href:
            lang === "en"
              ? defaultUrl.replace(`.com/`, ".com/es/")
              : defaultUrl.replace(`.com/${lang}`, ".com/es"),
          hrefLang: "es",
        },
        {
          rel: `alternate`,
          href: defaultUrl.includes(lang)
            ? defaultUrl.replace(`/${lang}`, "")
            : defaultUrl,
          hrefLang: "en",
        },
        {
          rel: `alternate`,
          href: defaultUrl.includes(lang)
            ? defaultUrl.replace(`/${lang}`, "")
            : defaultUrl,
          hrefLang: "x-default",
        },
        {
          rel: `preconnect`,
          href: defaultUrl,
        },
        {
          rel: `dns-prefetch`,
          href: defaultUrl,
        },
        {
          rel: `stylesheet`,
          href: `https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css`,
        },
      ]}
    >
      <meta name="viewport" content={viewport} />
      <meta name="description" content={description} />
      <meta name="image" content={metaImg} />
      <meta name="author" content={author} />
      {/* message for social networks */}
      <meta property="og:locale" content={lang} />
      <meta property="og:type" content="website" />
      <meta name="title" property="og:title" content={title} />
      <meta property="og:url" content={defaultUrl} />
      <meta property="og:site_name" content="N5 Now" />
      <meta property="og:description" content={description} />
      <meta name="image" property="og:image" content={metaImg} />
      <meta property="og:image:width" content="200" />
      <meta property="og:image:height" content="200" />
      <meta property="og:image:type" content="png" />
      {/* twitter  */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@n5now" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={metaImg} />
      {/* Structured data */}
      <script type="application/ld+json">{structData}</script>
      <script>{postHogScript}</script>
      {children}
    </Helmet>
  );
}

const config = {
  apiKey: "AIzaSyDDg9HZpwCEaIJzIJMSrjkFEor_A8OiZLM",
  authDomain: "n5-web.firebaseapp.com",
  projectId: "n5-web",
  storageBucket: "n5-web.appspot.com",
  messagingSenderId: "187584949931",
  appId: "1:187584949931:web:acdb038765733c2d42d1ee",
  measurementId: "G-WG8N2J1DS9",
};
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  originUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  siteUrl: PropTypes.string,
  children: PropTypes.element,
};

export default SEO;
