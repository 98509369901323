import React, { useEffect, useState } from "react";
import Navbar from "@organism/Navbar/Navbar";
import SEO from "../components/Seo";
import Calendly from "@organism/Calendly";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { isBrowser } from "@utils/isBrowser";
import PipeDriveChat from "@utils/pipedriveChat";

const SeeDemoPage = ({ location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const [chatStyle, setChatStyle] = useState(`textarea[class^="inputField_"]
    {
      color: #FFFFFF !important; 
       -webkit-text-fill-color: #FFFFFF !important;
    }`);
  const [clientChat, setClientChat] = useState(false);
  const [initChat, setinitChat] = useState(false);

  const listTokens = {
    es: "AW-10794307547/rRgzCK2mrf8CENufkJso",
    pr: "AW-10794307547/vudSCIPD2v8CENufkJso",
    en: "AW-10794307547/iHzlCNri0_8CENufkJso",
  };

  useEffect(() => {
    if (initChat) {
      if (isBrowser && clientChat !== false && clientChat !== t("JIVOCHAT")) {
        window.location.reload();
      }
    }
  }, []);

  return (
    <>
      <SEO
        title={t("HERO.BUTTON")}
        description={t("N5_IMPACT.SUBTITLE")}
        originUrl={location.origin}
        siteUrl={location.href}
        lang={language}
      >
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10794307547"
          type="text/javascript"
        />
        <meta
          name="linkedin pixel"
          content="https://px.ads.linkedin.com/collect/?pid=1272170&conversionId=6218961&fmt=gif"
        />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          "event", "conversion", {
            "send_to": "${listTokens[language]}",
          }
          `}
        </script>
      </SEO>
      <Navbar location={location} platform ideasVisible={false} />
      <div
        style={{
          background: "#fff",
        }}
      >
        <LinkedInPopUp />
        <Calendly />
        {/* <Chat
          chatStyle={chatStyle}
          clientChat={clientChat}
          initChat={initChat}
          openChat={openChat}
        /> */}
        <PipeDriveChat />
      </div>
    </>
  );
};

export default SeeDemoPage;
