import React from "react";
import { InlineWidget, CalendlyEventListener } from "react-calendly";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import styles from "./calendly.module.scss";

const Calendly = () => {
  const breakpoints = useBreakpoint();
  const { language } = useI18next();
  const lang = language === "pr" ? "pt" : language;

  return (
    <div className={styles.container}>
      <CalendlyEventListener
        onEventTypeViewed={() =>
          window.dataLayer.push({
            event: "virtualPageview",
            vpPath: "/" + lang + "/schedule-demo/type",
          })
        }
        onDateAndTimeSelected={() =>
          window.dataLayer.push({
            event: "virtualPageview",
            vpPath: "/" + lang + "/schedule-demo/onboarding-calendar",
          })
        }
        onEventScheduled={() =>
          window.dataLayer.push({
            event: "virtualPageview",
            vpPath: "/" + lang + "/schedule-demo/onboarding-confirm",
          })
        }
      >
        <div className="container">
        <div className={styles.div__notview_logopipedrive}></div>
        </div>
        <InlineWidget id="calendarPipeDrive"
          url="https://n5.pipedrive.com/scheduler/WJPzZPFE/demo-n5now"
          styles={{
            height: "100%",
            overFlowY: "hidden",
            paddingTop: breakpoints.sm ? "15%" : "10px",
          }}
        />
      </CalendlyEventListener>
    </div>
  );
};

export default Calendly;
