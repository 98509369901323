import React from "react";
import { Helmet } from "react-helmet";

const PipeDriveChat = () => {
  const configChatScript =
    "window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 7411403,playbookUuid: '24208787-d7b3-4340-8733-0acc74492a30',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();";
  const pipedriveScript =
    "https://leadbooster-chat.pipedrive.com/assets/loader.js";

  return (
    <div>
      <Helmet>
        <script type="text/javascript">{configChatScript}</script>
        <script src={pipedriveScript} async></script>
      </Helmet>
    </div>
  );
};

export default PipeDriveChat;
